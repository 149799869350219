import { FormGroup, ValidatorFn } from '@angular/forms';
import { UniversalOption } from './universal-option.interface';

export interface Validation {
  name: string;
  validator?: ValidatorFn;
  message: string;
  info?: string;
}

export type FieldType = 'checkbox' | 'input' | 'select' | 'button' | 'label';

export enum FieldTypes {
  checkbox = 'checkbox',
  select = 'select',
  input = 'input',
  button = 'button',
  label = 'label'
}

export interface FieldConfig {
  id?: string;
  type: string;
  label?: string;
  placeholder?: string;
  elementType?: string;
  options?: UniversalOption[];
  value?: any;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  validations?: Validation[];
}

export interface DynamicFormComponent {
  field: FieldConfig;
  formGroup: FormGroup;
}
