import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { TilTidEmployee, TilTidOrganization } from '../model/til-tid.interface';
import { environment } from '../../environments/environment';
import { SetupService } from './setup.service';
import { HttpClient } from '@angular/common/http';
import { IRefApp } from '../model/ref-app.interface';
import { Pagination } from '../model/pagination.interface';
import { map } from 'rxjs/operators';
import { FieldConfig, FieldTypes } from '../model/dynamic-form.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { ExportPlatform, ExportPlatforms } from '../model/perform-action.interface';
import { CandidateApplication } from '../classes/application.class';
import { AlvalabsCandidate, AlvalabsJob, AlvaTestProfile, CreateAlvaCandidatePayload } from '../model/alva-labs.interface';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  private _tilTidOrganizations: Map<number, TilTidOrganization[]> = new Map<number, TilTidOrganization[]>();
  private _TilTidEmployees: Map<string, Map<string, TilTidEmployee[]>> = new Map<string, Map<string, TilTidEmployee[]>>();

  constructor(
    private http: HttpClient,
    private setupService: SetupService,
    private sanitizer: DomSanitizer
  ) { }

  resetTilTid(): void {
    this._tilTidOrganizations = new Map();
    this._TilTidEmployees = new Map();
  }

  getExportPlatforms(application?: CandidateApplication): ExportPlatform[] {
    const exportPlatforms: ExportPlatform[] = [];
    const company = this.setupService.currentCompany;

    if (company.integratedWithTilTid) {
      exportPlatforms.push({
        translationKey: 'SHARED.SEND_TO_TIL_TID',
        propName: ExportPlatforms.TilTid,
        isExported: !!application?.exportedToTilTid
      });
    }

    if (company.integratedWithRefapp) {
      exportPlatforms.push({
        translationKey: 'SHARED.SEND_TO_REF_APP',
        propName: ExportPlatforms.RefApp,
        isExported: !!application?.exportedToRefapp
      });
    }

    if (company.integratedWithSri && application) {
      exportPlatforms.push({
        translationKey: 'SHARED.SRI_BG_CHECK',
        propName: ExportPlatforms.SriBgCheck,
        isExported: application.exportedToSri
      });
    }


    if (company.integratedWithAlva) {
      exportPlatforms.push({
        translationKey: 'ALVALABS.SEND_TO_ALVALABS',
        propName: ExportPlatforms.Alva,
        isExported: !!application?.exportedToAlva
      });
    }


    return exportPlatforms;
  }

  getTilTidOrganizations(): Observable<TilTidOrganization[]> {
    const companyId = this.setupService.companyId;
    const cachedTilTidOrganizations = this._tilTidOrganizations.get(companyId);

    if (cachedTilTidOrganizations) {
      return of(cachedTilTidOrganizations);
    }

    return this.http
      .get<TilTidOrganization[]>(
        `${environment.tilTidOrganizations}?company=${companyId}`
      )
      .pipe(
        tap((tilTidOrganizations: TilTidOrganization[]) => this._tilTidOrganizations.set(companyId, tilTidOrganizations))
      );
  }

  getTiltidEmployees(customerId: string): Observable<TilTidEmployee[]> {
    const companyGuid = this.setupService.companyGuid;
    const cachedTilTidEmployees = this._TilTidEmployees.get(companyGuid)?.get(customerId);

    if (cachedTilTidEmployees) {
      return of(cachedTilTidEmployees);
    }

    return this.http
      .get<TilTidEmployee[]>(
        `${environment.companies}/${companyGuid}/til_tid_employees?tilTidCustomerId=${customerId}`
      )
      .pipe(
        tap((tilTidEmployees: TilTidEmployee[]) => {
          if (this._TilTidEmployees.get(companyGuid)) {
            this._TilTidEmployees.get(companyGuid).set(customerId, tilTidEmployees);
          } else {
            const tiltidEmployee = new Map();
            tiltidEmployee.set(customerId, tilTidEmployees);
            this._TilTidEmployees.set(companyGuid, tiltidEmployee);
          }
        })
      );
  }

  exportToRefApp(applicationId: number, value: { [key: string]: any }): Observable<IRefApp> {
    return this.http.post<Pagination<IRefApp>>(`${environment.refAppReferenceCheck}/${applicationId}`, value)
      .pipe(
        map(({data}: Pagination<IRefApp>) => data[0])
      );
  }

  getRefAppConfig(appId: number): Observable<FieldConfig[]> {
    return this.http.get(`${environment.refAppConfig}/${appId}`)
      .pipe(
        map(({config}: {config: { fields: FieldConfig[] }}) => {
          const labelFields = ['header', 'subheader', 'paragraph'];
          const inputTypes = ['text', 'number', 'tel', 'email'];

          return config.fields
            .map((field: FieldConfig) => {
              if (inputTypes.includes(field.type)) {
                field.elementType = field.type;
                field.type = FieldTypes.input;
              } else if (labelFields.includes(field.type)) {
                field.elementType = field.type;
                field.type = FieldTypes.label;

                if (field['label-html']) {
                  field.label = this.sanitizer.bypassSecurityTrustHtml(field['label-html']) as string;
                }
              }

              return field;
            });
        })
      );
  }

  createAlvaJob(applicationId: number, payload: Partial<AlvalabsJob>): Observable<AlvalabsJob> {
    return this.http.post<AlvalabsJob>(`${environment.api}/alva_labs_job/${applicationId}`, payload);
  }

  getAlvaJob(applicationId: number): Observable<{data: AlvalabsJob}> {
    return this.http.get<{data: AlvalabsJob}>(`${environment.api}/alva_labs_job/${applicationId}`);
  }

  editAlvaJob(applicationId: number, payload: Partial<AlvalabsJob>): Observable<AlvalabsJob> {
    return this.http.post<AlvalabsJob>(`${environment.api}/alva_labs_job_update/${applicationId}`, payload);
  }

  //use with edit job
  attachAlvaTestProfileToJob(applicationId: number, testProfileId: string): Observable<AlvalabsJob> {
    return this.http.post<{data: AlvalabsJob}>(`${environment.api}/alva_labs_job_test_profile/${applicationId}`, {testProfileId})
      .pipe(
        map(response => response.data)
      );
  }


  getAlvaTestProfiles(applicationId: number): Observable<AlvaTestProfile[]> {
    return this.http.get<{data: AlvaTestProfile[]}>(`${environment.api}/alva_labs_test_profiles/${applicationId}`)
      .pipe(
        map(response => response.data)
      );
  }

  exportCandidateToAlva(applicationId: number, payload: CreateAlvaCandidatePayload): Observable<AlvalabsCandidate> {
    return this.http.post<AlvalabsCandidate>(`${environment.api}/alva_labs_candidate/${applicationId}`, payload);
  }

  getAlvaCandidate(applicationId: number): Observable<{data: AlvalabsCandidate}> {
    return this.http.get<{data: AlvalabsCandidate}>(`${environment.api}/alva_labs_candidate/${applicationId}`);
  }
}
