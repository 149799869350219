import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Modal } from '../classes/modal.class';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  _modalList: Modal[] = [];

  private _modals$: BehaviorSubject<Modal[]> = new BehaviorSubject<Modal[]>([]);
  private _modalClosed$: Subject<Modal> = new Subject<Modal>();
  private _renderer: Renderer2;
  private _activeModal$: BehaviorSubject<Modal> = new BehaviorSubject<Modal>(null);

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  get modals$(): Observable<Modal[]> {
    return this._modals$.asObservable();
  }

  get activeModal$(): Observable<Modal> {
    return this._activeModal$.asObservable();
  }

  get modalClosed$(): Observable<Modal> {
    return this._modalClosed$.asObservable()
      .pipe(
        delay(300)
      );
  }

  set renderer(renderer: Renderer2) {
    this._renderer = renderer;
  }

  public addModal(modal: Modal): void {
    if (this._modalList.length === 0) {
      this.hideScroll();
    }

    this._modalList.push(modal);
    this._modals$.next(this._modalList);
    this._activeModal$.next(this._modalList[this._modalList.length - 1]);
  }

  public removeModal(modal: Modal): void {
    const index = this._modalList.findIndex(mod => mod === modal);

    this._modalList.splice(index, 1);
    this._modals$.next(this._modalList);
    this._modalClosed$.next(modal);
    this._activeModal$.next(this._modalList[this._modalList.length - 1]);

    if (this._modalList.length === 0) {
      setTimeout(() => {
        this.showScroll();
      }, 300);
    }
  }

  public removeAllModals(): void {
    this._modalList = [];
    this._modals$.next(this._modalList);
    this._activeModal$.next(null);
  }

  public hideScroll(): void {
    const html = this.document.documentElement;

    if (this.isScrollBarVisible(html)) {
      const scrollWidth = this.getScrollbarWidth();
      this._renderer.setStyle(html, 'padding-right', `${scrollWidth}px`);
    }

    this._renderer.addClass(html, 'no-scroll');
  }

  public showScroll(): void {
    const html = this.document.documentElement;

    this._renderer.removeClass(html, 'no-scroll');
    this._renderer.removeStyle(html, 'padding-right');
  }

  private isScrollBarVisible(html: HTMLElement): boolean {
    const { left, right } = html.getBoundingClientRect();
    return left + right < window.innerWidth;
  }

  public getScrollbarWidth(): number {
    const scrollDiv = document.createElement('div');
    this._renderer.addClass(scrollDiv, 'scrollbar-measure');
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);

    return scrollbarWidth;
  }
}
