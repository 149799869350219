export enum PerformActionComponents {
  sms = 'sendSms',
  email = 'sendEmail',
  askForCv = 'sendAskForCv',
  tilTid = 'exportToTilTid',
  refapp = 'exportToRefapp',
  hide = 'hide',
  unhide = 'unhide',
  alva = 'exportToAlva'
}

export const PERFORM_ACTION_COMPONENTS: { name: PerformActionComponents, translationKey: string }[] = [
  {
    name: PerformActionComponents.sms,
    translationKey: 'SHARED.SEND_SMS'
  },
  {
    name: PerformActionComponents.email,
    translationKey: 'SHARED.SEND_EMAIL'
  },
  {
    name: PerformActionComponents.askForCv,
    translationKey: 'SHARED.ASK_FOR_CV'
  },
  {
    name: PerformActionComponents.tilTid,
    translationKey: 'BUTTONS.EXPORT'
  },
  {
    name: PerformActionComponents.refapp,
    translationKey: 'BUTTONS.EXPORT'
  },
  {
    name: PerformActionComponents.hide,
    translationKey: 'BUTTONS.HIDE'
  },
  {
    name: PerformActionComponents.unhide,
    translationKey: 'BUTTONS.UNHIDE'
  },
];

export interface ExportPlatform {
  name?: string,
  translationKey?: string,
  propName: ExportPlatforms,
  exportPropName?: string;
  integratedPropName?: string;
  isExported?: boolean;
  icon?: string;
}

export enum ExportPlatforms {
  TilTid = 'tilTid',
  RefApp = 'refapp',
  SriBgCheck = 'sriBgCheck',
  Alva = 'alva'
}

export const EXPORT_PLATFORMS: ExportPlatform[] = [
  {
    name: 'TIL-Tid',
    propName: ExportPlatforms.TilTid,
    exportPropName: 'exportedToTilTid',
    integratedPropName: 'integratedWithTilTid',
    icon: 'icon-til-tid',
  },
  {
    name: 'Refapp',
    propName: ExportPlatforms.RefApp,
    exportPropName: 'exportedToRefapp',
    integratedPropName: 'integratedWithRefapp',
    icon: 'icon-refapp'
  },
  {
    name: 'SRI',
    propName: ExportPlatforms.SriBgCheck,
    exportPropName: 'exportedToSri',
    integratedPropName: 'integratedWithSri',
    icon: 'icon-sri'
  },
  {
    name: 'Alva',
    propName: ExportPlatforms.Alva,
    exportPropName: 'exportedToAlva',
    integratedPropName: 'integratedWithAlva',
    icon: 'icon-alva'
  },
];
